.success {
    background-color: rgb(22, 167, 22);
}
.error {
    background-color: rgb(211, 18, 18);
}
.warning {
    background-color: rgb(204, 170, 2);
}
.snack {
    padding: .8rem 1.5rem;
    position: absolute;
    z-index: 1000;
    bottom: 10px;
    right: 10px;
    left:10px;
    border-radius: 1rem;
    justify-content: space-between;
    display: flex;
    justify-items: center;
    animation-name: move;
    animation-duration: 3.8s;
}
.iconSnack {
    
    font-size: 2rem;
}
.textSnack {
    color: #fff;
    font-weight: 700;
    line-height: 1.8;
    font-size: 1.5rem;

}
@keyframes move {
    0%{
        bottom: -3rem;
   }
    10%{
        bottom: 10px;
   }
   88%{
    bottom: 10px;
}
100%{
    bottom: -5rem;
}
}