.containerTopics {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}
.sectionTop {
    display: flex;
align-items: center;}
.buttonClose {
 
        background-color: #d4d4d4;
        margin:1.5rem;
        border-radius: 1rem;
        height: 3rem;
        width: 3rem;

        display: flex;
        justify-content: center;
        align-items: center;
    }
.titleTopic {
    color: #3F7CA8;
    font-size: 2rem;
    font-weight: 700;
    flex:1;
    text-align: center;
    margin-right: 6rem;
    }
.containerBodyTopics {
    margin-top: 1rem;
    padding: 1rem;
    flex: 1;
}