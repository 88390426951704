.imgComment {
    height: 6rem;
    width: 6rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
}
.imgIntoComment {
    min-height: 3rem;
    min-width: 3rem;
    height: 3rem;
}
.contentImgMsg {
    display: flex;
}