.containerHelps {
    padding: 2rem;
}
.help {
    display: flex;
    margin-bottom: 1rem;
    background-color: #F2F2F2;
    padding: .7rem;
    border-radius: 1rem;
    
}
.activeHelp {
    display: flex;
    margin-bottom: 1rem;
    background-color: #ffc9a4;
    padding: .7rem;
    border-radius: 1rem;
    
}

.dataHelp {
    padding: 0 1rem;
    flex: 1;
}
.nameHelp {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;

}
.descriptionHelp {
    font-size: 1.2rem;
}