.totalContent {
    flex-direction: column;
    position: fixed;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
  }
  .head {
      display: flex;
      align-items: center;
      box-shadow: 0 2px 5px #ddd;
  }
  .titleNP {
      color: #3F7CA8;
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      flex: 1;
      padding-right: 6rem;
  }
  .commentNP {
    color: #3F7CA8;
    background-size: 1.4rem;
    text-align: center;
    flex: 1;
    font-size: 1.8rem;
    font-weight: 700;
    margin: 1rem;
    padding: 1rem;
}
  .contentInName {
      padding: 2rem;
      display: flex;
  }
  .inName {
      background-color: #ddd;
      border: none;
      flex: 1;
      height: 4rem;
      border-radius: 1.2rem;
      font-size: 1.4rem;
      padding: 1rem;
      font-weight: 700;


  }
  .sectionHelp {
      flex:1;
  }
 
  .sectionButtons {
      display: flex;

  }
  .btn {
      padding: 2rem;
      flex: 1;
      border: none;
      font-size: 2rem;
      font-weight: 700;
  }
  .nextButton {
    background-color: #e7ecf0;
    color: #3F7CA8;
  }
 .bodyNP {
     flex: 1;
     flex-direction: column;
    display: flex;
 }