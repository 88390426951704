.HomeBody {
    display: flex;
    flex: 1;
    flex-direction: column;

    padding-top: 10rem;
}
.contentFiltersCategory {
    padding: .8rem;
    display: flex;
    width: 100%;
    overflow: auto;
    align-items: center;
   
}
.contentFiltersCategory::-webkit-scrollbar {
    display: none;
  }
@keyframes searching {
    0%   {    margin-left: 100%;   }
    100%  {    margin-left: 0;  
    }
   
  }
.titleFilter {
    font-size: 1.6rem;
    font-weight: 700;
    margin-right: 1rem;
    color: #ECAC08;
}
.contentButtonFilter {
    position: relative;
    flex:1;
    height: 3rem;
}
.buttonCatogory {
    display: flex;
    background-color: #075EA4;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 700;
    border: none;
    border-radius: .5rem;
    margin-right: 1rem;
    padding: .6rem 1rem !important;
    align-items: center;
    animation-name: searching;
    animation-duration: 1s;
}
.contentActiveFilter {
    display: flex;
    background-color: #075EA4;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 700;
    border: none;
    border-radius: .5rem;
    margin-right: 1rem;
    padding: .6rem 1rem !important;
}
.bodyPosts {
/*     background-color: #cccccc;
 */    width: 100%;
}
.btnNP {
    background-color: #ECAC08;
    padding: 2rem;
    margin-top: .5rem;
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
}
.start {
    font-size: 3rem;
    color: #dddddd;
}
.buttonCatogory {
    padding: .5rem;
}
.popUpAlerta {
    position: absolute;
    background-color: rgba(0, 0,0, .2);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.notFoundPosts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.buttonNotFoundContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 3rem;
    padding-bottom: 10rem;
}

.buttonNotFoundAdd {
    flex: 1;
    background-color: #ECAC08;
    border: none;
    font-size: 1.8rem;
    font-weight: 700;
    color: #fff;
    padding: 1rem;
    border-radius: 1rem;
}
.buttonNotFoundAdd:active {
    background-color: #d69b06;
    
}
.contentBodyPosts {
    display: flex;
    justify-content: center;
}
.textNotFoundPosts {
    padding: 2rem;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    color: #666;
}
.inPopEdit {
    margin: 1rem;
    margin-top: 1.4rem;
    width: calc(100% - 2rem);
    border: 1px solid gray;
    border-radius: .8rem;
    height: 3.8rem;
}
.bodyAlert {
    background-color: #fff;
    z-index: 1000;
    width: 80vw;
    padding: 1rem;
    padding-bottom: .5rem;
    padding-top: 1.5rem;
    border-radius: 1rem;
    box-shadow: .5rem .5rem .5rem rgba(0, 0,0, .2);
}
.titleAlert {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
}
.sectionButtonsAlert {
    display: flex;
    padding: 1rem;
    padding-top: 1.5rem;
}
.buttonAlert {
    background-color: #fff;
    font-weight: 700;
    flex: 1;
    font-size: 1.7rem;
    border: none;
    color: #479cFF;
}
@media (min-width: 600px) {
    .HomeBody {
        display: flex;
        justify-content: center;
    } 
    .bodyPosts{
        width: 40rem;
    }
    .extra{
        width: 30%;
    }
}

.contentPreloader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #ffffff;
}
.textNotMore {
    padding:2rem;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    color: #075EA4;
}
.box {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    height: 15px;
    width: 105px;
    display: flex;
    position: relative;
}
.container .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #075EA4;
    animation: move 500ms linear 0ms infinite;
    margin-right: 30px;
}
.container .circle:first-child {
    position: absolute;
    top: 0;
    left: 0;
    animation: grow 500ms linear 0ms infinite;
}
.container .circle:last-child {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0;
    animation: grow 500ms linear 0s infinite reverse;
}
@keyframes grow {
    from {
        transform: scale(0, 0);
        opacity: 0;
   }
    to {
        transform: scale(1, 1);
        opacity: 1;
   }
}
@keyframes move {
    from {
        transform: translateX(0px);
   }
    to {
        transform: translateX(45px);
   }
}