.stepAc {
    background-color: #3F7CA8;
    color: #fff;
    width: 3rem;
    height: 3rem;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    font-weight: 700;
    
}
.stepDs {
    font-weight: 700;
    background-color: #e2e2e2;
    color: rgb(27, 27, 27);
    width: 3rem;
    height: 3rem;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;

}
.sectionSteps {
    display: flex;
    justify-content: space-around;
    padding: 1rem 1.5rem;
}