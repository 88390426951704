.rangeValue {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    padding: 2rem;
    color: #36ab12;
}
.slider {
    width: 100%;
    
}

