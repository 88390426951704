.back {
    /*     background-color: rgba(0, 0, 0, .3);
     */   
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    
    
    .contentBody {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sectionLoginNew {
        display: flex;
        justify-content: space-around;
        padding: 1rem;
    }
    .sectionFinish {
        padding: 1rem;
    }
    .btnActionLogin {
        border: none;
        padding: .5rem 1rem;
        font-weight: 700;
        font-size: 2rem;
        width: 16rem;
        border-radius: 5rem;
    }
    .headerLogin {
        text-align: center;
        font-size: 3rem;
        font-weight: 700;
        padding: 2rem;
        margin-bottom: 2rem;
    }
  /*   .inputLogin{
        width: 70%;
        margin: 1rem 15%;
        border-radius: 5rem;
        border: none;
        background-color: #eeeeee;
        height: 4rem;
        padding: 1rem 2rem;
        font-size: 2rem;
    } */
    .btnActionLoginEntry {
        border: none;
        padding: .5rem 1rem;
        font-weight: 700;
        font-size: 2rem;
        width: 70%;
        border-radius: 5rem;
    }
    .contentBtn {
        margin: 2rem 1rem;
    
        display: flex;
        justify-content: center;
    }