
.modalLogin {
    background-color: #fff;
    flex: 1;
    border-radius: 2rem 2rem 0 0;
    box-shadow: 0 -.3rem 1.3rem 1rem rgba(0, 0, 0, .2);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 8rem;
    width: 100%;
  
}
.contentBodyLogin {
    background-color: #3F7CA8;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.my-facebook-button-class {
    width: 100%;
    background-color: #3F7CA8;
}
.signUpButton {
    color: #fff;
    font-weight: 700;
    font-size: 1.4rem;
    padding: 1rem;
    background-color: rgb(240, 158, 6);
    border-radius: 2rem;
}
.contetentBtnRead {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btnContentRead {
    padding: 1rem;
    text-align: center;
    color: #3F7CA8;
    font-weight: 600;
    font-size: 1.6rem;
}
.contentReturn {
    display: flex;
    align-items: center ;
}
.contentHeadLogin {
    padding: 1.5rem;
    font-size: 1.8rem;
    color: #fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.sectionReturn{
    width: 2.4rem;
    height: 2.4rem;
}
.sectionLoginNew {
    display: flex;
    justify-content: space-around;
    padding: 1rem;
}
.sectionFinish {
    padding: 1rem;
}
.btnActionLogin {
    border: none;
    padding: .5rem 1rem;
    font-weight: 700;
    font-size: 2rem;
    width: 16rem;
    border-radius: 5rem;
}
.headerLogin {
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    padding: 2rem;
    margin-bottom: 2rem;
}
.inputLogin{
    width: 90%;
    margin: 1rem 5%;
    border-radius: 1.5rem;
    border: 1px solid #3F7CA8;
    background-color: #ffffff !important;
    height: 5rem;
    padding: 1rem 2rem;
    font-size: 2rem;
}
.imgSignIn {
    width: 3rem;
    height: 3rem;
    left: 2rem;
    position: absolute;    
}
.buttonSignIn {
    border: 2px solid #3F7CA8;
    border-radius: 5rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 5rem;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 700;
    color: #3F7CA8;
}
.textButtonSignIn {
    font-size: 1.7rem;
    font-weight: 700;
}
.btnActionEntryLogin {

    border: none;
    padding: 1rem 1rem;
    font-weight: 700;
    font-size: 2rem;
    width: 100%;
    background-color: #3F7CA8;
    border-radius: 1.5rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnActionEntryLoginOut {
    border: .1rem solid #3F7CA8;
    padding: .9rem .9rem;
    font-weight: 700;
    font-size: 2rem;
    width: 100%;
    background-color: #FFF;
    border-radius: 1.5rem;
    color: #3F7CA8;
   
}
.containerLine {
    padding:1rem;
    display: flex;
    align-items: center;
}
.textInLine {
    font-weight: 700;
    font-size: 1.5rem;
    padding: 1.5rem;
}
.line {
    height: .2rem;
    flex: 1;
    background-color: #a9a9a9;
    border-radius: .5rem;
}
.contentBtn {
    margin: 2rem 1rem;

    display: flex;
    justify-content: center;
}
.sectionIcon {
    display: flex;
    justify-content: center;
}
.formInputs {
    width: 100vw;
}
.upImage {
    height: 8rem;
    height: 8rem;
}
.containerUpImage {
    padding: 1rem;
    border-radius: 1rem;
    background-color: #bbb;
    margin: 1rem;
}
.containerUpImageSelected {
    border-radius: 1rem;
    background-color: #bbb;
    margin: 1rem;
}
.upImageSelected {
    height: 10rem;
    height: 10rem;
}
.optionsImageProfile {
    display: flex;
    justify-content: center;
}
.buttonImageProfile {
    padding: 1rem;
    font-weight: 700;
    background-color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sectionLogin {
    padding-left: 1rem;
    padding-right: 1rem;
}
.sectionNewUser {
    padding-left: 1rem;
    padding-right: 1rem;
}
.buttonRemove {
    color: red;
}
.loaderButton {
    border: 0.5rem solid #ffffff8f;
    border-top: 0.5rem solid #ffffffd7;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    animation: spin 2s linear infinite;
    margin-left:1rem;
}