.userSection {
    background: linear-gradient(180deg, rgba(7,94,164,1) 0%, rgba(3,73,146,1) 59%);

}
.userImg {
    width: 5rem;
    height: 5rem;
    background-color: #aaaaaa;
    border-radius: 1rem;
    margin: 1rem;
}
.headOptionsContent {
    padding: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.nameUser {
    font-weight: 700;
    font-size: 1.6rem;
    color: #fff;
    text-transform: capitalize;
    flex: 1;

}
.optionsSection {
    flex: 1;
}
.closeSection {
    display: flex;
    justify-content: center;
    padding: 1rem;
}
.fSection {
    align-items: center;
    flex: 1;
    display: flex;
    justify-content: space-between;
}
.iconGearSettings {
    margin: 1rem;
}
.userLoged {
    display: flex;
    align-items: center;
}
.closeContent {
    height: fit-content;
    width: fit-content;
    background-color: #d4d4d4;
    margin:1rem;
    border-radius: 1rem;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.titleMenu {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
  }
.menu {
    display: flex;
    flex-direction: column;
}
.btnClose {
    border: none;
    padding: .8rem 2rem;
    border-radius: 1rem;
    color: #ec0e0e;
    font-weight: 700;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logIn{
    font-weight: 900;
    font-size: 1.6rem;
    color: #fff;
    padding: 1rem;
    background-color: rgba(255, 255, 255, .1);
    margin: 1rem;
    border-radius: 1rem;
}
.flSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}