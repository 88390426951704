.topBar {
    background-color: #075EA4;
    height: 6rem;
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: .5rem ;
    position: relative;
}

.bodyTopBar  {
  position: fixed;
    width: 100%;
    z-index: 100;
}
.optionsBar {
  background-color: #0b6ebf;
  display: flex;
 justify-content: space-between;



}
.showLocationComponent {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background-color: #075EA4;
  flex-direction: column;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.showRangeComponent {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  flex-direction: column;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.buttonOptionsBar {
  background-color: #0b6ebf;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 1.6rem;
  padding: .8rem 1.5rem;
}
.sectionButtonsUpdateLocation {
 padding-top: 0;
 display: flex;
 justify-content: space-between;
 padding: 1.5rem;
}
.buttpnUpLocC {
  flex: 1;
  border: none;
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  background-color: rgb(130, 130, 130);
  padding: 1rem;
  border-radius: 0.5rem;

}
.buttpnUpLocA {
  flex: 2;
  background-color: rgb(5, 168, 5);
  border: none;
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-left: 1rem;


}
.buttpnUpLocC:active {
  background-color: rgb(100, 100, 100);
  
}
.buttpnUpLocA:active {
  background-color: rgb(3, 122, 3);
  
}
.buttonOptionsBar:active {
  background-color: #0960a7;

}

.searchButton {
  background-color: rgba(255, 255, 255, .1);
  border: none;
  height: 4rem;
  width: 4rem;
  border-radius: 2rem;
  margin-right: .5rem;
}
.searchButton:active {
  background-color: #044b85;

}
/* .contentLogo {
  width: 40%;
  height: 6rem;
  position: absolute;
  display: flex;
  justify-content: center;
  right: 30%;
  left: 30%;
} */
.categories {
    background-color: #09437D;

    height: 3rem;
    color: white;
    padding: .5rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    border-radius: 2rem;
    margin-right: 2rem;
    padding: 1rem;
    
}
.contentCat {
    display: flex;
}
.buttonSearching {
  background-color: #fff;
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.6rem;
  font-weight: 700;
  align-items: center;
  border-bottom: .2rem solid #eee;
}
.buttonSearching:active{
  background-color: #c8e6ff;
}
.searchBox{
    height: 3.4rem;
    border-radius: 1rem;
    border: none;
    animation-name: searching;
    width: 100%;
  }
  .contentBoxHelps {
    display: block;
    background-color: #fff !important;
    height: 1000px;
    position: absolute;
    z-index: 10000;
    right: 2rem;
    left:2rem;
    height: fit-content;
    top:4.8rem;
    border-radius: 0 0 1rem 1rem;
    box-shadow: .2rem .3rem .5rem rgba(0, 0, 0, .3);
    overflow: hidden;
  }
  .searchBoxContent {
    border-radius: 1rem;
    height: 4rem;
    animation-name: searching;
    animation-duration: .5s;
    width: 100%;
    margin: 0 1rem;
    display: flex;
    background-color: #fff;
    align-items: center;
    border: .2rem solid orange;


}
  @keyframes searching {
    0%   {    width: 0%;    }
    100%  { width: 100%;  }
   
  }
  .mobile {
    background-color: rgba(255, 255, 255, .1);
    border: none;
    height: 4rem;
    width: 4rem;
    border-radius: .5rem;
    margin-left: .5rem;
  }
  .addPost {
    background-color: #ECAC08;
    border: none;
    height: 4rem;
    width: 4rem;
    border-radius: .5rem;
    margin-left: .5rem;
  }
  
  @media (min-width: 600px) {
    .mobile {
      display: none;
      background-color: #075EA4;
      border-radius: none;
    }
    .searchBox{
      display: block;
      border: none;
      border-radius: 1rem;
      width: 40%;
      margin-right: 1rem;
    }
    .topBar {
     
      
        justify-content: center;
        align-items: center;
        padding: 1rem ;
        
    }
    .contentCat {
        width: 40%;
        justify-content: right;
    }
    
  }
  