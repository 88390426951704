.contentActionsDetails {
    background-color: #eee;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
}
.sectionDataDetails {
    padding: 2rem;
    padding-bottom: 0;
}
.titleDataDetails {
    font-size: 2rem;
    color: #4A8DBD;
}
.contentDataDetails {
    font-size: 1.4rem;
    margin-top: .8rem;
    font-weight: 500;
}
.sectionsDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.actionDetails {
    width: 3rem;
    height: 3rem;
}
.contentMapDetails {
    padding: 1rem;
    margin-top: 2rem;
}
.mapDetails {
    background-color: rgb(221, 221, 221);
    height: 18rem;
    border-radius: 2rem;
    overflow: hidden;
}
.mapAddress {
    background-color: #4A8DBD;
    color: white;
    display: flex;
    padding: 1rem;
    align-items: center;
}
.addressTextDetails {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-size: 1.2rem;
}
.sectionTagsDetails {
    display: flex;
}
.tagDetails {
    background-color: #4A8DBD;
    font-size: 1.4rem;
    color: #fff; 
    padding: .4rem .6rem;
    font-weight: 700;
    border-radius: .6rem;
    margin-top: .4rem;
    margin-right: 1rem;
}
.sectionVauesDetails {
    padding:1rem;
}
.valuesDetail {
    display: flex;
    align-items: center;
    margin: 1rem 0;
}
.contentValue {
    flex: 1;
}
.contentWithOutComments {
    color: #888888;
    text-align: center;
    font-weight: 700;
    font-size: 1.4rem;
    margin: 3rem;
}
.moreComments {
    text-align: center;
    padding: 3rem;
    font-size: 1.8rem;
    font-weight: 700;
    background-color: #e1f1fc;
    color: #4A8DBD;
}
.contentHeaderDetails {
    display: flex;
    align-items: center;
}
.tagTypeDetails {
    background-color: #479cFF;
    padding: .5rem  1rem;
    margin-left: -5rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2rem ;
    height: 3.2rem;
}
.tagImagesDetails {
    background-color: #3fd72e;
    padding: .5rem  1rem;
    margin-right: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2rem ;
    height: 3.2rem;
    text-decoration: underline;
}
.contentCategorieDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    right: 0;
    position: absolute;
    
}
.commentComponentContent {
    padding: 2rem 0;
}