.principalImage {
    max-width:100%;
max-height:100%;
}
.tagType {
    background-color: #479cFF;
    padding: .5rem  1rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    width: fit-content;
    border-radius: 0 2rem 2rem 0;
}
.dataSection {
    background: linear-gradient(rgba(255,0,0, .0), rgba(0,0,0,.4), rgba(0,0,0,.6));
    display: flex;
    bottom: 0;
    right: 0; 
    left: 0;
    position: absolute;
}
.mid {
    width: 50%;
    
}
.title {
    font-weight: 700;
    padding: 1.2rem;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.titleh1 {
    font-size: 2rem;
    text-transform: capitalize;
}
.score {
    background-color: #479cFF;
    color: #fff;
    font-size: 1.6rem;
    
    margin-left: 1rem;
    border-radius: .5rem;
    font-weight: 700;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.statusPost {
    background-color: red;
    color: #fff;
    padding: .5rem 1.5rem;
    font-size: 1.5rem;
    border-radius: 2rem;
    font-weight: 700;
    
}
.statusPostSN {
    background-color: rgb(69, 68, 68);
    color: #fff;
    padding: .5rem 1.5rem;
    font-size: 1.5rem;
    border-radius: 2rem;
    font-weight: 700;
    margin-top: 1rem;
    
}
.contentStatus {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
}
.contentScore {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem; 
    align-items: center;
    padding-bottom: 1rem;
}
.start {
    padding-bottom: .2rem;
}
.startActive {
    color: #f4cf00 !important;
}
.contentMeta {
    padding: 1rem;
    display: flex;

}
.comment{
    background-color: #fff;
    padding-bottom: 1rem;
}
.sectionUserFrist {
    display: flex;
    justify-content: space-between;
}
.sectionUserSecond {
    color: black;
    display: flex;
    justify-content: space-between;

}
.sectionData {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    font-size: 1.4rem;
    font-weight: 700;
    color: gray;
}
.start2 {
    color: #ddbd07;
}
.commentPopulate {
    background-color: #dddddd;
    padding: 1rem;
    margin: 0 1rem;
    border-radius: 1rem;
    font-size: 1.3rem;
}
.contentActions {
    background-color: #eee;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
}
.actions {
    width: 5rem;
    height: 5rem;
    padding: 0 1rem;

}
.actionOne {
    width: fit-content;
}
.actionTwo {
    width: fit-content;

}
