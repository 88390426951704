
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

:root {
  --primary-blue: #1a9af7;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}
html {
  font-size: 62.5%;
  /* font family general */
}
input {
  padding: .5rem;
  font-weight: 500;
}
input:focus {
  outline-width: 0;
}
.menu {
  flex: 1;
  top: 0;
  bottom: 0;
  left: 0;
  width: 70%;
  background-color: #fff;
}
.backD {
  flex-direction: column;
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0,0,0, .3);
  z-index: 100000;
}
.App {
  display: flex;
  flex-direction: column;
}
input:focus {
  outline-offset: 0;
}
input {
  font-size: 16px !important; 
  font-weight: 500 !important;
  }
  button {
    border: none;
    background-color: transparent;
  }