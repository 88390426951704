.contentMap {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.contentInAdress {
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    padding: 2rem;
}
.inAdress {
    height: 100%;
    flex: 1;
    border-radius: 1.4rem;
    height: 4rem;
    border: none;
    box-shadow: 2px 2px .5rem rgba(0, 0, 0, .3) ;
    font-weight: 700;
    padding-left: 1rem;
}
.location-search-input {
    width: 100%;
    border-radius: 1.4rem;
    height: 4rem;
    border: none;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .3) ;
    font-weight: 700;
    padding-left: 1rem;
}
.boxAdressGoogle {
    flex: 1;
    display: flex;
}
.btnActualLocation {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .3) ;
    background-color: #F19F00;
    border: none;
    height: 4rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: .3rem;
    border-radius: 1.4rem;
    margin-left: 1rem;
}
.sectionMap {
    width: 100%;
    flex: 1;
    overflow: hidden;
}
.contentDataLocation {
    position: absolute;
    bottom: 0;
}

.contentAdress {
    font-size: 1.8rem;
    font-weight: 600;
    padding: 2rem;
    margin:1rem 2rem;
    margin-bottom: 0;
    background-color: #eee;
    border-radius: 1.4rem;
    border-bottom: solid gray 2px;

}
.option {
    padding: 1rem;
    font-weight: 700;
    font-size: 1.6rem;
    background-color: #eee;
    width: calc(50% - 1rem);
    margin: .5rem;
    float: left;
}
.optionActive {
    padding: 1rem;
    font-weight: 700;
    font-size: 1.6rem;
    color: #fff;
    background-color: #3F7CA8;
    width: calc(50% - 1rem);
    margin: .5rem;
    float: left;
}
.contentOptions {
    flex:1;
    
}
.contentImages {
    flex-flow: row wrap;
    display: flex;
}
.sectionThanks {
    align-items: center;
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: end;
}
.sectionMessageNP {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex:1;
}
.thanksNP {
    font-size: 7rem;
    font-weight: 700;
    color: #3F7CA8;
    text-align: center;
}
.messageNP {
    font-size: 3rem;
    font-weight: 700;
    color: #444;
    text-align: center;
}
.btnNPF {

    padding: 1rem 1.4rem;
    border: none;
    font-size: 1.8rem;
    font-weight: 700;
     border-radius: 1rem;
     width: 10rem;
}
.btnl {
    color: #fff;
    background-color: #888;
}
.btnr {
    
    background-color: #23BF20;
    color: #fff;
}
.sectionButtonsNP {
    display: flex;
    justify-content:space-around;
    padding: 3rem ;
    width: 100%;
}
.sectionImgeNP {
    flex: 2;
    justify-content: center;
    align-items: center;
    display: flex;
}
.imgNP {
    max-width: 80vw;
    max-height: 80vw;
}
.map {
    width: 100%;
    height: 100%;
    border-radius: 1.4rem;
}

.contentDrop {
    position: relative;
}
.autocomplete-dropdown-container {
    position: absolute;
    z-index: 100;
    width: calc( 100% );
    border-radius: 1.4rem;
    overflow: hidden;
    margin-top: 1rem;
}
.suggestion-item,.suggestion-item--active {
    width: 100%;
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: 700;
}
.suggestion-item--active {
    background-color: #3F7CA8;
}
.contentData {
    display: flex;
    padding: 2rem;
    position: relative;
}
.contentDataComplete {
    
    display: flex;
}
.inputData {
 border: none;
 height: 4rem;
 background-color: #eee;
 border-bottom: solid gray 2px;
 width: 100%;
 border-radius: 1.4rem;
 padding-left: 3rem;
 font-weight: 700;
 font-size: 1.6rem;
}
.inputDataF {
 padding-left: 1.5rem !important;
 
}
.inputData:focus {
    border: none;
    border-bottom: solid rgb(255, 187, 0) 3px;
   }
.titleData {
    position: absolute;
    font-weight: 700;
    margin: .4rem .8rem;
    color: gray;
    font-size: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: .6rem;
}
/*   ///three */
.botonAddImmage {
    padding:.5rem;
    background-color: #F19F00;
    color: #fff; 
    margin: 2rem;
    font-weight: 700;
    font-size: 1.6rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.botonAddImmage:active {
    background-color: #da9202;
    
}

