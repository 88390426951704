.contentRead {
    padding: 2rem;
    font-size: 1.6rem;
    text-align: justify;
}
.titleRead {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    padding: 2.5rem;
    background-color: #075EA4;
    color: #fff;
}