.contentComments {

    background-color: #fff;
    display: flex;
    height: 100vh;
    flex-direction: column;
}
.contentHeaderComments {
    background-color: aliceblue;
    height: 6rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 700;

}
.closeContentComment {
 
    background-color: rgba(255,255,255, .4);
    margin:1rem;
    border-radius: 1rem;
    height: 3rem;
    width: 3rem;
    left: 1rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.titleComments {
    max-width: 70vw;
}
.contentComment {
    display: flex;
}
.contentBodyComments {
    padding: 2rem;
    flex: 1;
    overflow: auto;
    padding-bottom: 10rem;

}
.contentImageComment {
    width: 6rem;
    height: 6rem;
    background-color: aquamarine;
    border-radius: 3rem;
}
.contentDataComment {
    flex: 1;
    margin-left: 1rem;
}
.nameUserComments {
    font-size: 1.8rem;
    padding-bottom: .5rem;
    font-weight: 700;
}
.contentMessage {
    font-weight: 500;
    padding: 1rem;
    background-color: #ebebeb;
    font-size: 1.4rem;
    border-radius: 1rem;

}
.dataMessage {
    text-align: right;
    padding: .5rem;
    font-weight: 700;
    font-size: 1.2rem;

}
.contentMetaComment {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rateComments {
    font-size: .7rem;
    display: flex;
    align-items: center;
}
.rateCommentNumber {
    
    font-size: 1.4rem;
    padding-right: 1rem;

}
.startActiveComment {
    color: #f4cf00;
    font-size: 1.6rem;

}
.contentInputComments {
    padding: 1rem;
    display: flex;
    background-color: #efefef;
    
}
.inputComments {
    font-weight: 700;
    padding-left: 1rem;
    height: 4rem;
    flex:1;
    border: none;
    border-radius: 1rem;
    margin-right: 1.5rem;
}
.btn-send {
    border: none;
    background-color: #22a227;
    font-weight: 700;
    border-radius: 1rem;
    color: #fff;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

}
.btn-camera {
    border: none;
    font-weight: 700;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .5rem;;

}
.cameraCommentImg {
    width: 3rem;
    height: 3rem;
}
.sendCommentImg {

}
.contentPictureOptions {
    position: absolute;
    top:0;
    z-index: 10;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .3);
    display: flex;
    justify-content: center;
    align-items: center;
}
.contentOptionsComments {
    background-color: #fff;
    width: 70vw;
    border-radius: 2rem;
}
.optionPicture {
    font-size: 2rem;
    font-weight: 700;
    padding: 2rem;
}
.headerOptionsPictures {
    position: relative;
    font-size: 1.4rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

}
.contentPictures {
    display: flex;
    background-color: #efefef;

}
.contentPic {
    height: 6rem;
    width: 6rem;
    margin: 1rem;
    background-color: #efefef;
    border-radius: 1rem;
}
.picToSend {
    width: 6rem;
    height: 6rem;
    border-radius: .5rem;
    background-color: rgb(155, 155, 155);
}
.buttonCloseToSend {
    height: 2.8rem;
    width: 2.8rem;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backLoadingImg {
    border-radius: .7rem;
    height: 100%;
    width: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .4);
}
.loader {
    border: .8rem solid #ffffff8f; /* Light grey */
    border-top: .8rem solid #ffffffd7; /* Blue */
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .contentAddScore {
    position: absolute;
    background-color: #fff;
    bottom: 0;
    right: 0;
    left: 0;
      padding: .5rem;
      border-top: .2rem solid #aaa;
      border-radius: 2rem 2rem 0 0;
      padding-top: 3rem;
  }
  .titleFeature {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .elementFeature {
      margin: .5rem;
  }
  .titleText {
      font-size: 1.6rem;
      font-weight: 700;
      color: #4A8DBD;
  }
  .contentScoreFeature {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
  }
  .buttonSendFeature {
      flex: 1;
      font-size: 1.8rem;
      font-weight: 700;
      padding: 1rem;
      background-color: #22a227;
      color: #fff;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      margin: 1rem;
  }

  .contentAddComments {
    left: 0;
    position: fixed;
    bottom: 0;
    right: 0;
  }