.buttonShare {
    display: flex ;
    justify-content: left; 
    align-items: center;
    padding: 10px;
    box-shadow: 0 8px 6px -5px rgba(0,0,0,0.5);
    width: 100%;
    background-color: #fff;
    border: none;
 }

 .textButtonShare {padding:10px;
    font-size: 2rem;
    font-Weight: 600;
}
.closeActionShare {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    height: 2rem;


}