.backgroundWebcam {
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    position: fixed;
    display: flex;
    flex-direction: column;
}
.contentWebCam {
    display: flex;
    flex:1;
}
.contentPictureT {
    flex: 1 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sectionHeadButtons {

    height: 6rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.buttonChangeCamera {

    background-color: rgba(0, 0, 0, .3);
    z-index: 20;
    height: 4rem;
    width: 4rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttonCloseCamera {
    z-index: 20;

    background-color: rgba(0, 0, 0, .3);
    height: 4rem;
    width: 4rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgCamera {

}
.sectionControlsCamera {
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    z-index: 20;
    height: 8rem;
    width: 100%;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttonRedCamera {
    height: 6rem;
    width: 6rem;
    background-color: red;
    border-radius: 3rem;
    border: .2rem solid white;
}
.webcamVideoCapture {
    width: 100%;
}
.btnCamera {
    color: #fff;
    background-color: rgba(255,255,255, .2);
    flex: 1;
    margin: 2rem;
    padding: 1.4rem;
    font-size: 1.6rem;
    border-radius: 1rem;
    border: .2rem solid white;
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.actionCamera {
    width: 2rem;
    height: 2rem;
}